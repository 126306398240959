import React from "react";
import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";
import "./ExperienceAccordion.css";
import { Accordion, Panel } from "baseui/accordion";
import { DarkTheme, LightTheme, ThemeProvider } from "baseui";
import Fade from 'react-reveal/Fade';

function ExperienceAccordion(props) {
  const theme = props.theme;

  // return (
  //   <div className="experience-accord">
  //     <ThemeProvider theme={theme.name === "light" ? LightTheme : DarkTheme}>
  //       <Accordion onChange={({ expanded }) => console.log(expanded)}>
  //         {props.sections.map((section) => {
  //           return (
  //             <Panel
  //               className="accord-panel"
  //               title={section["title"]}
  //               key={section["title"]}
  //             >
  //               {section["experiences"].map((experience) => {
  //                 return (
  //                   <ExperienceCard experience={experience} theme={theme} />
  //                 );
  //               })}
  //             </Panel>
  //           );
  //         })}
  //       </Accordion>
  //     </ThemeProvider>
  //   </div>
  // );

  // Remove collapsible link
  return (
    <div className="main" id="experiences">
      <div className="experiences-header-div"> 
        <Fade bottom duration={2000} distance="20px">
        </Fade>
      </div>
      <div className="experiences-body-div"> 
        {props.sections.map((section) => {
          return (
            <div className="experiences-panel" key={section.title}>
              <h3>{section.title}</h3>
              {section.experiences.map((experience, index) => (
                <ExperienceCard key={index} experience={experience} theme={theme} />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ExperienceAccordion;
